import React from 'react';
import { Helmet } from 'react-helmet';
import siteData from '../data/siteData.json';

const SEO = ({ title, description, image, article }) => {
  const seo = {
    title: title || siteData.siteMetadata.title,
    description: description || siteData.siteMetadata.description,
    url: siteData.siteMetadata.siteUrl,
    // Default OG image
    image: `${siteData.siteMetadata.siteUrl}${image || siteData.siteMetadata.image}`,
    // Platform-specific images
    facebookImage: `${siteData.siteMetadata.siteUrl}${siteData.siteMetadata.socialImages.facebook}`,
    twitterImage: `${siteData.siteMetadata.siteUrl}${siteData.siteMetadata.socialImages.twitter}`,
    linkedinImage: `${siteData.siteMetadata.siteUrl}${siteData.siteMetadata.socialImages.linkedin}`,
  };

  return (
    <Helmet title={seo.title} titleTemplate={siteData.siteMetadata.titleTemplate}>
      <meta name="description" content={seo.description} />
      <meta name="image" content={seo.image} />

      {/* Facebook Meta Tags */}
      <meta property="og:url" content={seo.url} />
      <meta property="og:type" content={article ? 'article' : 'website'} />
      <meta property="og:title" content={seo.title} />
      <meta property="og:description" content={seo.description} />
      {/* Facebook/OG specific image */}
      <meta property="og:image" content={seo.facebookImage} />
      <meta property="og:image:width" content="1200" />
      <meta property="og:image:height" content="630" />

      {/* LinkedIn specific image */}
      <meta property="og:image" content={seo.linkedinImage} />
      <meta property="og:image:width" content="1200" />
      <meta property="og:image:height" content="627" />

      {/* Twitter Meta Tags */}
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content={seo.title} />
      <meta name="twitter:description" content={seo.description} />
      <meta name="twitter:image" content={seo.twitterImage} />
      <meta name="twitter:image:width" content="1200" />
      <meta name="twitter:image:height" content="600" />
    </Helmet>
  );
};

export default SEO;
