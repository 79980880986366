import React from 'react';
import { Box } from '@mui/material';
import PropTypes from 'prop-types';
import { useGame } from '../../contexts/GameContext';
import CollectibleWord from './CollectibleWord';
import gameData from '../../data/gameData.json';

const TextWithCollectibles = ({ text, variant, component, location, sx = {} }) => {
  const { collectWord } = useGame();

  const renderContent = () => {
    // First count occurrences in the entire text block
    const fullText = text.replace(/\s*\|\|\s*/g, ' '); // Replace || with space
    const allWords = fullText.trim().split(' ');
    const runningCounts = {};
    
    // Map of word positions to their occurrence number
    const wordPositions = new Map();
    
    allWords.forEach((rawWord, index) => {
      const word = rawWord.replace(/[.,!?:;]$/, '').toLowerCase();
      runningCounts[word] = (runningCounts[word] || 0) + 1;
      wordPositions.set(index, {
        word,
        occurrence: runningCounts[word]
      });
    });
    
    // Now split into paragraphs and render
    const paragraphs = text.split('||');
    const result = [];
    let wordIndex = 0;
    
    paragraphs.forEach((paragraph, paragraphIndex) => {
      const parts = [];
      const words = paragraph.trim().split(' ');
      
      words.forEach((rawWord, index) => {
        const position = wordPositions.get(wordIndex++);
        const word = position.word;
        const currentOccurrence = position.occurrence;

        // Strip array index from location for comparison
        const locationBase = location.replace(/\[\d+\]$/, '');
        const wordLocationBase = location.includes('[') ? locationBase : location;
        
        const collectibleWord = gameData.wordLocations.find(
          w => w.word.toLowerCase() === word && 
               w.location.replace(/\[\d+\]$/, '') === wordLocationBase && 
               (!w.occurrence || w.occurrence === currentOccurrence)
        );

        if (collectibleWord) {
          // Keep the original word with punctuation
          parts.push(
            <CollectibleWord 
              key={`word-${paragraphIndex}-${index}`} 
              word={rawWord} 
              onCollect={collectWord}
              location={location}
              occurrence={currentOccurrence}
            />
          );
        } else {
          parts.push(rawWord);
        }

        // Add a real space character between words
        if (index < words.length - 1) {
          parts.push(' ');
        }
      });

      result.push(
        <Box
          key={`p-${paragraphIndex}`}
          component="p"
          sx={{
            mb: paragraphIndex < paragraphs.length - 1 ? 2 : 0,
            ...sx
          }}
        >
          {parts}
        </Box>
      );
    });
    
    return result;
  };

  return (
    <Box
      component={component || 'div'}
      sx={sx}
    >
      {renderContent()}
    </Box>
  );
};

TextWithCollectibles.propTypes = {
  text: PropTypes.string.isRequired,
  variant: PropTypes.string,
  component: PropTypes.elementType,
  location: PropTypes.string.isRequired,
  sx: PropTypes.object
};

export default TextWithCollectibles;
