import React from 'react';
import { Box, Container, Typography, Link, Grid } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import { mainMenu } from '../config/navigation';
import siteData from '../data/siteData.json';

const Footer = () => {
  return (
    <Box
      component="footer"
      sx={{
        py: 6,
        bgcolor: 'background.default',
        borderTop: '1px solid',
        borderColor: 'divider'
      }}
    >
      <Container maxWidth="xl">
        <Grid container spacing={4}>
          <Grid item xs={12} sm={4}>
            <Typography 
              variant="subtitle1" 
              sx={{ 
mb: 1
              }}
            >
              Blackinch Studio
            </Typography>
            <Typography 
              variant="body2" 
              sx={{ 
                color: 'text.primary',
                maxWidth: 300,
                lineHeight: 1.6
              }}
            >
              I create beautiful digital experiences that inspire and engage.
            </Typography>
          </Grid>
          
          <Grid item xs={12} sm={4}>
            <Typography 
              variant="subtitle1" 
              sx={{ 
                mb: 1
              }}
            >
              Navigation
            </Typography>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
              {mainMenu.map((item) => (
                <Link
                  key={item.title}
                  component={RouterLink}
                  to={item.path}
                  sx={{
                    color: 'text.primary',
                    textDecoration: 'none',
                    fontSize: '0.875rem',
                    '&:hover': {
                      color: 'text.secondary'
                    }
                  }}
                >
                  {item.title}
                </Link>
              ))}
            </Box>
          </Grid>
          
          <Grid item xs={12} sm={4}>
            <Typography 
              variant="subtitle1" 
              sx={{ 
                mb: 1
              }}
            >
              Contact
            </Typography>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
              <Link 
                component="a"
                href={`mailto:${siteData.contact.email}`}
                sx={{
                  color: 'text.primary',
                  textDecoration: 'none',
                  fontSize: '0.875rem',
                  '&:hover': {
                    color: 'text.secondary'
                  }
                }}
              >
                {siteData.contact.email}
              </Link>
              <Link
                href={`tel:${siteData.contact.phone}`}
                sx={{
                  color: 'text.primary',
                  textDecoration: 'none',
                  fontSize: '0.875rem',
                  '&:hover': {
                    color: 'text.secondary'
                  }
                }}
              >
                {siteData.contact.phone}
              </Link>
              <Typography variant="body2" color="text.primary">
                {siteData.contact.address}
              </Typography>
            </Box>
          </Grid>
        </Grid>


      </Container>
              {/* Footer text and copyright */}
              <Box 
                    sx={{
                      p: 3,
                      bgcolor: 'background.default',
                      borderTop: '1px solid',
                      borderColor: 'divider',
                      mt: 3,
                      textAlign: 'left'
                    }}
              
              >
          <Typography 
            variant="body2" 
            color="text.secondary"
            sx={{ 
           
              mx: 'auto',
              mb: 3,
              lineHeight: 1.6,
              fontSize: '0.780rem',
            }}
          >
            {siteData.footer.text}
          </Typography>
          <Typography 
            variant="body2" 
            color="text.primary"
            sx={{ 
           
              mx: 'auto',
              mb: 3,
              lineHeight: 1.6,
              fontSize: '0.780rem',
            }}
          >
            {siteData.footer.copyright}
          </Typography>
        </Box>
    </Box>
  );
};

export default Footer;
