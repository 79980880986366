import React, { useState, useRef, useCallback, useEffect } from 'react';
import { Box, Typography, keyframes } from '@mui/material';
import { useGame } from '../../contexts/GameContext';

// Define the pulse animation
const pulse = keyframes`
  0% { transform: scale(1); }
  50% { transform: scale(1.2); color: #000; }
  100% { transform: scale(1); }
`;

const CollectibleWord = ({ word, onCollect, location, occurrence }) => {
  const { isGameActive } = useGame();
  const [isCollected, setIsCollected] = useState(false);
  const [hasAnimated, setHasAnimated] = useState(false);
  const wordRef = useRef(null);

  useEffect(() => {
    const timer = setTimeout(() => {
      setHasAnimated(true);
    }, 1500);
    return () => clearTimeout(timer);
  }, []);

  const handleClick = useCallback(() => {
    if (!isCollected && isGameActive) {
      const rect = wordRef.current.getBoundingClientRect();
      const startPosition = {
        left: rect.left + window.scrollX,
        top: rect.top + window.scrollY,
        width: rect.width,
        height: rect.height
      };
      setIsCollected(true);
      onCollect(word, location, occurrence, startPosition);
    }
  }, [isCollected, onCollect, word, location, occurrence, isGameActive]);

  if (!isGameActive) {
    return <Box component="span">{word}</Box>;
  }

  return (
    <Box
      ref={wordRef}
      component="span"
      onClick={handleClick}
      sx={{
        cursor: isCollected ? 'default' : 'pointer',
        '&:hover': {
          color: isCollected ? 'inherit' : 'primary.main',
        },
        transition: 'color 0.2s ease',
        textDecoration: isCollected ? 'underline' : 'none',
        animation: !hasAnimated ? `${pulse} 1s ease-in-out` : 'none',
        display: 'inline-block', // Needed for transform to work
      }}
    >
      {word}
    </Box>
  );
};

export default CollectibleWord;
