import React from 'react';
import { useParams, Link as RouterLink } from 'react-router-dom';
import {
  Typography,
  Box,
  Grid,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Button,
  Paper,
} from '@mui/material';
import CircleIcon from '@mui/icons-material/Circle';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import PortfolioGrid from '../components/PortfolioGrid';
import PageLayout from '../layouts/PageLayout';
import pagesData from '../data/pagesData.json';
import portfolioData from '../data/portfolioData.json';

const ServiceFeatures = ({ features }) => (
  <Box sx={{ mt: 4 }}>
    <Typography variant="h4" gutterBottom>
      What We Offer
    </Typography>
    <List>
      {features.map((feature, index) => (
        <ListItem key={index}>
          <ListItemIcon sx={{ minWidth: 24 }}>
            <CircleIcon sx={{ fontSize: 8, color: 'text.primary' }} />
          </ListItemIcon>
          <ListItemText primary={feature} />
        </ListItem>
      ))}
    </List>
  </Box>
);

const ServiceProcess = ({ process }) => (
  <Paper elevation={0} sx={{ p: 0 }}>
    <Typography variant="h5" gutterBottom>
      Our Process
    </Typography>
    <List>
      {process.map((step, index) => (
        <ListItem key={index} >
          <ListItemText primary={`${index + 1}. ${step}`} />
        </ListItem>
      ))}
    </List>
  </Paper>
);

const RelatedProjects = ({ service, projects }) => (
  <Box sx={{ mt: 6 }}>
    <Typography variant="h4" gutterBottom>
      Featured {service.title} Projects
    </Typography>
    <Box sx={{ mt: 3 }}>
      <PortfolioGrid 
        projects={projects
          .filter(project => !project.commented && project.category === service.id)
          .slice(0, 4)} 
      />
    </Box>
  </Box>
);

const ServiceDetail = () => {
  const { serviceId } = useParams();
  const service = pagesData.services.servicesList.find(s => s.id === serviceId);

  if (!service) {
    return (
      <PageLayout
        hero={{
          title: 'Service Not Found',
          subtitle: 'The requested service could not be found'
        }}
      >
        <Button 
          component={RouterLink} 
          to="/services"
          startIcon={<ArrowBackIcon />}
          sx={{ mt: 4 }}
        >
          Back to Services
        </Button>
      </PageLayout>
    );
  }

  return (
    <PageLayout
      hero={{
        title: service.title,
        subtitle: service.shortDescription,
        backgroundImage: service.image
      }}
      maxWidth="lg"
    >
      <Grid container spacing={6}>
        <Grid item xs={12} md={12} lg={8}>
          <Typography variant="body1" paragraph>
            {service.detailedDescription}
          </Typography>

          <ServiceFeatures features={service.features} />
          <RelatedProjects 
            service={service} 
            projects={portfolioData.projects} 
          />
        </Grid>

        <Grid item xs={12} md={12} lg={4}>
          <ServiceProcess process={service.process} />

          <Box sx={{ mt: 4 }}>
            <Button
              component={RouterLink}
              to={`/portfolio?category=${service.id}`}
              variant="contained"
              color="secondary"
              fullWidth
            >
              See All {service.title} Projects
            </Button>
          </Box>
        </Grid>
      </Grid>
    </PageLayout>
  );
};

export default ServiceDetail;
