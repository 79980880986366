import React from 'react';
import { Box, Tabs, Tab, Fade } from '@mui/material';
import { useSearchParams } from 'react-router-dom';
import PageLayout from '../layouts/PageLayout';
import PortfolioGrid from '../components/PortfolioGrid';
import pagesData from '../data/pagesData.json';
import portfolioData from '../data/portfolioData.json';

const Portfolio = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const categoryParam = searchParams.get('category');

  // Create categories from services list plus 'All' option
  const categories = React.useMemo(() => [
    { id: 'all', title: 'All Projects' },
    ...pagesData.services.servicesList.map(service => ({
      id: service.id,
      title: service.title
    }))
  ], []);

  const initialTabIndex = React.useMemo(() => {
    if (!categoryParam) return 0;
    const index = categories.findIndex(cat => cat.id === categoryParam);
    return index >= 0 ? index : 0;
  }, [categoryParam, categories]);

  const [currentTab, setCurrentTab] = React.useState(initialTabIndex);

  const handleTabChange = (event, newValue) => {
    const category = categories[newValue];
    setCurrentTab(newValue);
    if (category.id === 'all') {
      setSearchParams({});
    } else {
      setSearchParams({ category: category.id });
    }
  };

  const getFilteredProjects = (categoryId) => {
    // First filter out commented projects
    const activeProjects = portfolioData.projects.filter(project => !project.commented);
    
    // Then filter by category if needed
    if (categoryId === 'all') {
      return activeProjects;
    }
    return activeProjects.filter(
      (project) => project.category === categoryId
    );
  };

  React.useEffect(() => {
    if (categoryParam) {
      const index = categories.findIndex(cat => cat.id === categoryParam);
      if (index >= 0) {
        setCurrentTab(index);
      }
    }
  }, [categoryParam, categories]);

  return (
    <PageLayout
      hero={{
        title: pagesData.portfolio.title,
        subtitle: pagesData.portfolio.subtitle,
        backgroundImage: pagesData.portfolio.backgroundImage
      }}
      maxWidth="lg"
    >
      <Box sx={{ 
          overflowX: 'hidden',
          width: { xs: '100vw', md: 'auto' },
          position: { xs: 'relative', md: 'static' },
          left: { xs: '50%', md: 'auto' },
          right: { xs: '50%', md: 'auto' },
          mx: { xs: '-50vw', md: -3 },
          px: { xs: 4, md: 3 }
      }}>
        <Tabs
          value={currentTab}
          onChange={handleTabChange}
          variant="scrollable"
          scrollButtons="auto"
          aria-label="portfolio categories"
          sx={{
            borderBottom: 1,
            borderColor: 'divider',
            mb: 4,
            bgcolor: 'background.paper'
          }}
        >
          {categories.map((category) => (
            <Tab
              key={category.id}
              label={category.title}
              sx={{
                minWidth: 'auto',
                px: 2,
                py: 1.5,
                textTransform: 'none',
                fontSize: { xs: '0.875rem', sm: '1rem' }
              }}
            />
          ))}
        </Tabs>
      </Box>

      {categories.map((category, index) => (
        <Box
          key={category.id}
          role="tabpanel"
          hidden={currentTab !== index}
          id={`portfolio-tabpanel-${category.id}`}
          aria-labelledby={`portfolio-tab-${category.id}`}
        >
          <Fade in={currentTab === index} timeout={600}>
            <Box sx={{ display: currentTab === index ? 'block' : 'none' }}>
              <PortfolioGrid 
                projects={getFilteredProjects(category.id)} 
              />
            </Box>
          </Fade>
        </Box>
      ))}
    </PageLayout>
  );
};

export default Portfolio;
