import React from 'react';
import { GameProvider } from './contexts/GameContext';
import WordCollector from './components/game/WordCollector';
import ScrollToTop from './components/ScrollToTop';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import { AnimatePresence } from 'framer-motion';
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import theme from './theme/theme';
import PageTransition from './components/PageTransition';
import SidebarLayout from './layouts/SidebarLayout';
import SEO from './components/SEO';
import Home from './pages/Home';
import About from './pages/About';
import Services from './pages/Services';
import ServiceDetail from './pages/ServiceDetail';
import Portfolio from './pages/Portfolio';
import Contact from './pages/Contact';

function App() {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Router>
        <GameProvider>
          <AppContent />
          <WordCollector />
        </GameProvider>
      </Router>
    </ThemeProvider>
  );
}

const AppContent = () => {
  const location = useLocation();
  
  const getPageTitle = () => {
    const path = location.pathname;
    if (path === '/') return 'Home';
    return path.charAt(1).toUpperCase() + path.slice(2).split('/')[0];
  };

  return (
    <>
      <ScrollToTop />
      <SEO title={getPageTitle()} />
      <SidebarLayout>
        <AnimatedRoutes />
      </SidebarLayout>
    </>
  );
};

const AnimatedRoutes = () => {
  const location = useLocation();

  return (
    <AnimatePresence mode="wait">
      <Routes location={location} key={location.pathname}>
        <Route path="/" element={<PageWrapper><Home /></PageWrapper>} />
        <Route path="/about" element={<PageWrapper><About /></PageWrapper>} />
        <Route path="/creative-things" element={<PageWrapper><Services /></PageWrapper>} />
        <Route path="/creative-things/:serviceId" element={<PageWrapper><ServiceDetail /></PageWrapper>} />
        <Route path="/portfolio" element={<PageWrapper><Portfolio /></PageWrapper>} />
        <Route path="/contact" element={<PageWrapper><Contact /></PageWrapper>} />
      </Routes>
    </AnimatePresence>
  );
};

const PageWrapper = ({ children }) => (
  <PageTransition>
    {children}
  </PageTransition>
);

export default App;
