import React from 'react';
import { Box, Typography, keyframes } from '@mui/material';

const openLid = keyframes`
  0% { transform: rotateX(0deg); }
  100% { transform: rotateX(-120deg); }
`;

const closeLid = keyframes`
  0% { transform: rotateX(-120deg); }
  100% { transform: rotateX(0deg); }
`;

const CollectorBox = ({ totalWords, collectedWords, isCollecting, children }) => {
  const wordCount = collectedWords.length;
  const showCollector = wordCount === totalWords;

  if (showCollector) {
    return children;
  }

  return (
    <Box
      sx={{
        position: 'fixed',
        bottom: 20,
        right: 20,
        width: 120,
        height: 120,
        perspective: '1000px',
        transformStyle: 'preserve-3d',
        zIndex: 1000,
      }}
    >
      {/* Box lid */}
      <Box
        sx={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '30%',
          bgcolor: 'black',
          transformOrigin: 'top',
          animation: isCollecting ? `${openLid} 0.5s forwards` : `${closeLid} 0.5s forwards`,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      />

      {/* Box body */}
      <Box
        sx={{
          position: 'absolute',
          bottom: 0,
          left: 0,
          width: '100%',
          height: '100%',
          bgcolor: 'black',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          color: 'white',
          textAlign: 'center',
          padding: 2,
        }}
      >
        <Typography variant="h6" sx={{ fontSize: '1rem', mb: 1 }}>
          {wordCount}/{totalWords}
        </Typography>
        <Typography variant="body2" sx={{ fontSize: '0.8rem' }}>
          COLLECT
        </Typography>
      </Box>
    </Box>
  );
};

export default CollectorBox;
