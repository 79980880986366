import React from 'react';
import PropTypes from 'prop-types';
import { Box, Container, Typography } from '@mui/material';

const Section = ({ title, subtitle, children, backgroundColor }) => {
  return (
    <Box
      component="section"
      sx={{
        py: 8,
        backgroundColor: backgroundColor || 'background.paper'
      }}
    >
      <Container maxWidth="lg">
        {title && (
          <Typography
            variant="h2"
            component="h2"
            align="center"
            sx={{
              mb: subtitle ? 2 : 6,
              fontSize: { xs: '2rem', md: '3rem' },
              fontWeight: 'bold'
            }}
          >
            {title}
          </Typography>
        )}
        
        {subtitle && (
          <Typography
            variant="h5"
            component="h3"
            align="center"
            color="text.secondary"
            sx={{
              mb: 6,
              maxWidth: '800px',
              mx: 'auto'
            }}
          >
            {subtitle}
          </Typography>
        )}
        
        {children}
      </Container>
    </Box>
  );
};

Section.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  children: PropTypes.node,
  backgroundColor: PropTypes.string
};

export default Section;
