import React from 'react';
import TextWithCollectibles from '../components/game/TextWithCollectibles.js';
import {
  Grid,
  Box,
  Typography,
  Card,
  CardContent,
  Link,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Stack,
  Divider,
  useTheme
} from '@mui/material';
import CircleIcon from '@mui/icons-material/Circle';
import PageLayout from '../layouts/PageLayout';
import siteData from '../data/siteData.json';
import pagesData from '../data/pagesData.json';
import SocialLinks from '../components/SocialLinks';

const ContactCard = ({ title, children }) => (
  <Box sx={{ height: '100%', py: 3 }}>
    <Typography variant="h4" gutterBottom sx={{ mb: 3 }}>
      {title}
    </Typography>
    {children}
  </Box>
);

const Contact = () => {
  const theme = useTheme();

  return (
    <PageLayout
      hero={{
        title: pagesData.contact.title,
        subtitle: pagesData.contact.subtitle
      }}
      maxWidth="xl"
    >
      <Grid container spacing={6}>
        {/* Project Guidelines Section */}
        <Grid item xs={12} md={8}>
          <ContactCard title="Starting a Project">
            <Typography variant="subtitle1" sx={{ mb: 4 }} paragraph>
              <TextWithCollectibles
                text={pagesData.contact.helpText}
                location="contact.helpText"
              />
            </Typography>
            <List sx={{ mb: 4 }}>
              {pagesData.contact.requirements.map((requirement, index) => (
                <ListItem key={index}>
                  <ListItemIcon sx={{ minWidth: 24 }}>
                    <CircleIcon sx={{ fontSize: 8, color: 'text.primary' }} />
                  </ListItemIcon>
                  <ListItemText primary={requirement} />
                </ListItem>
              ))}
            </List>
            {/* <Box sx={{ 
              bgcolor: 'grey.50', 
              color: 'text.primary',
              p: 3,
              mt: 4
            }}>
              <Typography variant="subtitle1" gutterBottom>
                Quick Tip
              </Typography>
              <Typography variant="body2" sx={{ mb: 4, fontStyle: 'italic' }}>
                The more details you can provide about your project, the better I can understand your vision and provide accurate timing and pricing estimates.
              </Typography>
            </Box> */}
          </ContactCard>
        </Grid>

        {/* Get in Touch Section */}
        <Grid item xs={12} md={4}>
          <ContactCard title="Get in Touch">
            <Stack spacing={4}>
              <Box>
                <Typography variant="subtitle1" gutterBottom>
                  Email
                </Typography>
                <Link 
                  href={`mailto:${siteData.contact.email}`}
                  sx={{ 
                    color: 'text.primary',
                    textDecoration: 'none',
                    '&:hover': { color: 'text.secondary' }
                  }}
                >
                  {siteData.contact.email}
                </Link>
              </Box>
              <Box>
                <Typography variant="subtitle1" gutterBottom>
                  Phone
                </Typography>
                <Link 
                  href={`tel:${siteData.contact.phone}`}
                  sx={{ 
                    color: 'text.primary',
                    textDecoration: 'none',
                    '&:hover': { color: 'text.secondary' }
                  }}
                >
                  {siteData.contact.phone}
                </Link>
              </Box>
              <Box>
                <Typography variant="subtitle1" gutterBottom>
                  Location
                </Typography>
                <Typography variant="body1" color="text.primary">
                  {siteData.contact.address}
                </Typography>
              </Box>
              <Box>
                <Typography variant="subtitle1" gutterBottom>
                  Connect
                </Typography>
                <SocialLinks />
              </Box>
            </Stack>
          </ContactCard>
        </Grid>
      </Grid>
    </PageLayout>
  );
};

export default Contact;
