import React from 'react';
import { Container, Box } from '@mui/material';
import Hero from '../components/Hero';
import Section from '../components/Section';
import Footer from '../components/Footer';

const PageLayout = ({ 
  hero = null,
  children,
  maxWidth = 'lg',
  disableHero = false
}) => {
  return (
    <Box sx={{ minHeight: '100vh', display: 'flex', flexDirection: 'column', overflowX: 'hidden' }}>
      <Box sx={{ flex: 1 }}>
        <Section>
          <Container maxWidth={maxWidth} sx={{ px: { xs: 2, sm: 3 }, overflowX: 'hidden' }}>
            {!disableHero && hero && (
              <Hero 
                title={hero.title}
                subtitle={hero.subtitle}
              />
            )}
            {children}
          </Container>
        </Section>
      </Box>
      <Footer />
    </Box>
  );
};

export default PageLayout;
