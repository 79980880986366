import React from 'react';
import { Box, IconButton } from '@mui/material';
import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';
import InstagramIcon from '@mui/icons-material/Instagram';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import GitHubIcon from '@mui/icons-material/GitHub';
import siteData from '../data/siteData.json';

const iconMap = {
  facebook: FacebookIcon,
  twitter: TwitterIcon,
  instagram: InstagramIcon,
  linkedin: LinkedInIcon,
  github: GitHubIcon,
};

const SocialLinks = ({ color = 'text.primary', hoverColor = 'text.secondary', size = 'small' }) => {
  return (
    <Box sx={{ display: 'flex', gap: 1 }}>
      {siteData.socialLinks.map(({ platform, url }) => {
        const Icon = iconMap[platform.toLowerCase()];
        if (!Icon) return null;

        return (
          <IconButton
            key={platform}
            href={url}
            target="_blank"
            rel="noopener noreferrer"
            size={size}
            sx={{
              color,
              '&:hover': {
                color: hoverColor,
                backgroundColor: 'transparent'
              }
            }}
          >
            <Icon sx={{ fontSize: size === 'small' ? '1.2rem' : '1.5rem' }} />
          </IconButton>
        );
      })}
    </Box>
  );
};

export default SocialLinks;
