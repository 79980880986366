export const mainMenu = [
  { title: 'Home', path: '/' },
  { title: 'About', path: '/about' },
  { title: 'Creative Things', path: '/creative-things' },
  { title: 'Portfolio', path: '/portfolio' },
  { title: 'Contact', path: '/contact' }
];

export const footerLinks = {
  // services: [
  //   { title: 'Branding', path: '/services#branding' },
  //   { title: 'Web Design', path: '/services#web-design' },
  //   { title: 'Digital Marketing', path: '/services#digital-marketing' }
  // ],
  // legal: [
  //   { title: 'Privacy Policy', path: '/legal/privacy-policy' },
  //   { title: 'Terms of Service', path: '/legal/terms-of-service' }
  // ]
};

export default {
  mainMenu,
  footerLinks
};
