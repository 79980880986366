import React from 'react';
import PropTypes from 'prop-types';
import { Box, Typography, Button, Container } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import TextWithCollectibles from './game/TextWithCollectibles';

const CallToAction = ({ title, description, image, buttonText }) => {
  return (
    <Box
      sx={{
        position: 'relative',
        py: 4,
        overflow: 'hidden',
        bgcolor: 'grey.100'
      }}
    >
      <Container maxWidth="lg">
        <Box
          sx={{
            display: 'flex',
            flexDirection: { xs: 'column', md: 'column', lg: 'row' },
            gap: 6
          }}
        >
          <Box
            sx={{
              width: { xs: '100%', md: '100%', lg: '50%' },
              height: { xs: '200px', sm: '300px', md: '400px' },
              flexShrink: 0,
              position: 'relative',
              overflow: 'hidden'
            }}
          >
            <Box
              component="img"
              src={image}
              alt="Call to action"
              sx={{
                width: '100%',
                height: '100%',
                objectFit: 'cover'
              }}
            />
          </Box>
          <Box
            sx={{
              width: { xs: '100%', md: '100%', lg: '50%' }
            }}
          >
            <Typography
              variant="h3"
              component="h2"
              sx={{
                mb: 3,
                fontWeight: 'bold',
                color: 'text.primary',
              }}
            >
              <TextWithCollectibles
                text={title}
                location="home.cta.title"
              />
            </Typography>
            {description.split('||').map((text, index) => (
              <Typography
                key={index}
                variant="body1"
                paragraph
                sx={{
                  fontSize: '1.1rem',
                  lineHeight: 1.6,
                  color: 'text.primary',
                  mb: index === description.split('||').length - 1 ? 4 : 2
                }}
              >
                {text.trim()}
              </Typography>
            ))}
            <Button
              component={RouterLink}
              to="/contact"
              variant="contained"
              size="large"
              sx={{
                backgroundColor: 'common.white',
                color: 'common.black',
                '&:hover': {
                  backgroundColor: 'grey.100'
                }
              }}
            >
              {buttonText}
            </Button>
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

CallToAction.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
  buttonText: PropTypes.string.isRequired
};

export default CallToAction;
