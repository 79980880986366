import React from 'react';
import { Typography, Box } from '@mui/material';
import CallToAction from '../components/CallToAction';
import PageLayout from '../layouts/PageLayout';
import ServiceGrid from '../components/ServiceGrid';
import siteData from '../data/siteData.json';
import pagesData from '../data/pagesData.json';

const Home = () => {
  return (
    <PageLayout
      maxWidth="xl"
    >

      
        <Box sx={{ mb: { xs: 8, md: 12 } }}>
          <Typography 
            variant="h1" 
            sx={{ 

              mb: 3,
              maxWidth: '600px'
            }}
          >
            {pagesData.home.title}
          </Typography>

          <Typography
            variant="body1"
            sx={{
              fontSize: { xs: '1rem', md: '1.125rem' },
              color: 'text.primary',
              maxWidth: '600px',
              mb: 8
            }}
          >
            {pagesData.home.subtitle}
          </Typography>



          <Box sx={{ mt: { xs: 8, md: 12 }, mb: { xs: 8, md: 12 } }}>
          <CallToAction 
            title={pagesData.home.cta.title}
            description={pagesData.home.cta.description}
            buttonText={pagesData.home.cta.buttonText}
            image={pagesData.home.cta.image}
          />
        </Box>



        <Typography variant="h4" gutterBottom sx={{ mb: 6 }}>
      Creative Things
    </Typography>
          <ServiceGrid services={pagesData.services.servicesList} />






        </Box>


    </PageLayout>
  );
};

export default Home;
