import React from 'react';
import { Grid, Typography, Box } from '@mui/material';
import TextWithCollectibles from '../components/game/TextWithCollectibles';
import PageLayout from '../layouts/PageLayout';
import ServiceGrid from '../components/ServiceGrid';
import siteData from '../data/siteData.json';
import pagesData from '../data/pagesData.json';

const About = () => {
  return (
    <PageLayout
      hero={{
        title: pagesData.about.title,
        subtitle: pagesData.about.subtitle
      }}
      maxWidth="xl"
    >

      <Grid container spacing={6} alignItems="top" sx={{  mb: { xs: 8, md: 12 } }}>
        <Grid item xs={12} md={12} lg={6}>
          <Box
            component="img"
            src={pagesData.about.image}
            alt="Profile"
            sx={{
              width: '100%',
              aspectRatio: '1/1',
              objectFit: 'cover'
            }}
          />
        </Grid>
        <Grid item xs={12} md={12} lg={6}>
          <Typography variant="h4" gutterBottom>
            {pagesData.about.title}
          </Typography>
          {pagesData.about.content.map((paragraph, index) => (
              <TextWithCollectibles
                key={index}
                text={paragraph}
                component="p"
                location={`about.content[${index}]`}
                sx={{
                  typography: 'body1',
                  color: 'text.secondary',
                  mb: 2 // equivalent to paragraph prop
                }}
              />
          ))}
        </Grid>
      </Grid>

      <Typography variant="h4" gutterBottom sx={{ mb: 6 }}>
      Creative Things
    </Typography>
        <ServiceGrid services={pagesData.services.servicesList} />
    
    </PageLayout>
  );
};

export default About;
