import React, { createContext, useContext, useState, useEffect } from 'react';
import gameData from '../data/gameData.json';

const GameContext = createContext();

export const useGame = () => {
  const context = useContext(GameContext);
  if (!context) {
    throw new Error('useGame must be used within a GameProvider');
  }
  return context;
};

export const GameProvider = ({ children }) => {
  const [collectedWords, setCollectedWords] = useState([]);
  const [success, setSuccess] = useState(false);
  const isGameActive = gameData.isGameActive;

  // Success is now handled in the WordCollector component
  useEffect(() => {
    const allCollected = collectedWords.length === gameData.wordLocations.length;
    const currentOrder = collectedWords.map(w => w.word);
    const correctOrder = JSON.stringify(currentOrder) === JSON.stringify(gameData.targetOrder);
    setSuccess(allCollected && correctOrder);
  }, [collectedWords]);

  const [wordAnimation, setWordAnimation] = useState(null);

  const collectWord = (word, location, occurrence, startPosition) => {
    const wordKey = `${word}-${location}-${occurrence}`;
    if (!collectedWords.find(w => w.key === wordKey)) {
      // Trigger animation if startPosition is provided
      if (startPosition) {
        setWordAnimation(startPosition);
      }

      setCollectedWords([...collectedWords, { 
        id: Date.now(), 
        word,
        key: wordKey,
        location,
        occurrence 
      }]);
    }
  };

  const reorderWords = (items) => {
    setCollectedWords(items);
  };

  const value = {
    collectedWords,
    collectWord,
    reorderWords,
    success,
    targetPhrase: gameData.targetPhrase,
    wordAnimation,
    setWordAnimation,
  };

  return (
    <GameContext.Provider value={value}>
      {children}
    </GameContext.Provider>
  );
};

export default GameContext;
