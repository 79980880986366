import React from 'react';
import { Typography } from '@mui/material';
import PageLayout from '../layouts/PageLayout';
import ServiceGrid from '../components/ServiceGrid';
import pagesData from '../data/pagesData.json';

const Services = () => {
  return (
    <PageLayout
      hero={{
        title: pagesData.services.title,
        subtitle: pagesData.services.subtitle
      }}
      maxWidth="xl"
    >

      <ServiceGrid services={pagesData.services.servicesList} />
    </PageLayout>
  );
};

export default Services;
