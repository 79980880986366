import React, { useState, useEffect, useRef, useMemo } from 'react';
import {
  Box,
  Paper,
  Typography,
  IconButton,
  Collapse,
  Alert,
  keyframes,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import MinimizeIcon from '@mui/icons-material/Minimize';
import MaximizeIcon from '@mui/icons-material/Maximize';
import gameData from '../../data/gameData.json';
import { useGame } from '../../contexts/GameContext';
import CollectorBox from './CollectorBox';

const WordCollector = () => {
  const { collectedWords, success, reorderWords, wordAnimation, setWordAnimation, isGameActive } = useGame();
  const [isMinimized, setIsMinimized] = useState(false);
  const [draggedWord, setDraggedWord] = useState(null);
  const [targetIndex, setTargetIndex] = useState(null);
  const [touchStartX, setTouchStartX] = useState(null);
  const [touchStartY, setTouchStartY] = useState(null);
  const [touchedWord, setTouchedWord] = useState(null);
  const [touchStartIndex, setTouchStartIndex] = useState(null);
  const [isCollecting, setIsCollecting] = useState(false);
  const containerRef = useRef(null);
  const totalWords = gameData.wordLocations.length;

  // Check if all words have been collected
  const allWordsCollected = useMemo(() => {
    return collectedWords.length === totalWords;
  }, [collectedWords.length, totalWords]);

  // Check if words are in correct order
  const wordsInCorrectOrder = useMemo(() => {
    if (!allWordsCollected) return false;
    const currentOrder = collectedWords.map(w => w.word);
    return JSON.stringify(currentOrder) === JSON.stringify(gameData.targetOrder);
  }, [collectedWords, allWordsCollected]);

  // Calculate drop zones for touch events
  const dropZones = useMemo(() => {
    return collectedWords.map((_, index) => ({
      index,
      left: index * 100, // Simplified calculation
      right: (index + 1) * 100
    }));
  }, [collectedWords]);

  useEffect(() => {
    if (wordAnimation) {
      setIsCollecting(true);
      const timer = setTimeout(() => {
        setWordAnimation(null);
        // Close the lid after the word is collected
        setTimeout(() => {
          setIsCollecting(false);
        }, 500);
      }, 1500); // Match this with the CSS animation duration
      return () => clearTimeout(timer);
    }
  }, [wordAnimation, setWordAnimation]);

  if (!isGameActive) return null;

  const handleDragStart = (e, word, index) => {
    setDraggedWord({ word, index });
    e.dataTransfer.effectAllowed = 'move';
    e.target.style.opacity = '0.4';
  };

  const handleDragEnd = (e) => {
    e.target.style.opacity = '1';
    if (draggedWord && targetIndex !== null && targetIndex !== draggedWord.index) {
      const items = Array.from(collectedWords);
      const [removed] = items.splice(draggedWord.index, 1);
      items.splice(targetIndex, 0, removed);
      reorderWords(items);
    }
    setDraggedWord(null);
    setTargetIndex(null);
  };

  const handleDragOver = (e, index) => {
    e.preventDefault();
    e.dataTransfer.dropEffect = 'move';
    if (draggedWord && index !== targetIndex) {
      setTargetIndex(index);
    }
  };

  // Touch event handlers
  const handleTouchStart = (e, word, index) => {
    const touch = e.touches[0];
    setTouchStartX(touch.clientX);
    setTouchStartY(touch.clientY);
    setTouchedWord({ word, index });
    setTouchStartIndex(index);
    e.target.style.opacity = '0.4';
  };

  const handleTouchMove = (e) => {
    if (!touchedWord) return;

    e.preventDefault(); // Prevent scrolling while dragging
    const touch = e.touches[0];
    const wordElements = document.querySelectorAll('[data-word-index]');
    
    // Find the word element we're hovering over
    let closestIndex = touchStartIndex;
    let minDistance = Infinity;

    wordElements.forEach((element) => {
      const rect = element.getBoundingClientRect();
      const distance = Math.abs(touch.clientX - (rect.left + rect.width / 2));
      if (distance < minDistance) {
        minDistance = distance;
        closestIndex = parseInt(element.dataset.wordIndex);
      }
    });

    if (closestIndex !== targetIndex) {
      setTargetIndex(closestIndex);
    }
  };

  const handleTouchEnd = (e) => {
    if (!touchedWord) return;

    e.target.style.opacity = '1';
    if (targetIndex !== null && targetIndex !== touchedWord.index) {
      const items = Array.from(collectedWords);
      const [removed] = items.splice(touchedWord.index, 1);
      items.splice(targetIndex, 0, removed);
      reorderWords(items);
    }

    // Reset touch state
    setTouchStartX(null);
    setTouchStartY(null);
    setTouchedWord(null);
    setTouchStartIndex(null);
    setTargetIndex(null);
  };

  return (
    <CollectorBox
      totalWords={totalWords}
      collectedWords={collectedWords}
      isCollecting={isCollecting}
    >
      <Paper
        elevation={3}
        sx={{
          position: 'fixed',
          bottom: 20,
          right: 20,
          width: isMinimized ? 'auto' : 'auto',
          maxWidth: '90vw',
          zIndex: 1000,
          borderRadius: 2,
          overflow: 'hidden'
        }}
      >
      <Box sx={{ 
        bgcolor: 'primary.main', 
        color: 'primary.contrastText',
        p: 1,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between'
      }}>
        <Typography variant="subtitle2">
          Word Collector
        </Typography>
        <Box>
          <IconButton 
            size="small" 
            onClick={() => setIsMinimized(!isMinimized)}
            sx={{ color: 'inherit', mr: 1 }}
          >
            {isMinimized ? <MaximizeIcon /> : <MinimizeIcon />}
          </IconButton>
        </Box>
      </Box>

      <Collapse in={!isMinimized}>
        <Box sx={{ p: 2 }}>
          <Typography variant="body2" sx={{ mb: 2 }}>
            {gameData.description}
          </Typography>

          <Box
            ref={containerRef}
            sx={{
              height: 60,
              bgcolor: 'grey.100',
              p: 2,
              borderRadius: 1,
              overflowX: 'auto',
              position: 'relative',
            }}
          >
            {wordAnimation && (
              <Box
                sx={{
                  position: 'fixed',
                  left: wordAnimation.left,
                  top: wordAnimation.top,
                  width: wordAnimation.width,
                  height: wordAnimation.height,
                  pointerEvents: 'none',
                  zIndex: 9999,
                  animation: 'flyToCollector 1.5s cubic-bezier(0.34, 1.56, 0.64, 1) forwards',
                  '@keyframes flyToCollector': {
                    '0%': {
                      transform: 'scale(1)',
                      opacity: 1,
                      fontWeight: 400,
                    },
                    '20%': {
                      transform: 'scale(1.3)',
                      opacity: 1,
                      fontWeight: 700,
                    },
                    '40%': {
                      transform: 'scale(1.3) translateY(-20px)',
                      opacity: 1,
                      fontWeight: 700,
                    },
                    '100%': {
                      transform: 'scale(0.7) translateY(-100px)',
                      opacity: 0,
                      fontWeight: 400,
                    },
                  },
                }}
              >
                <Typography
                  variant="body2"
                  sx={{
                    color: 'primary.main',
                    fontWeight: 'inherit',
                    transition: 'all 0.3s ease',
                  }}
                >
                  {wordAnimation.word}
                </Typography>
              </Box>
            )}
            <Box
              sx={{
                position: 'absolute',
                top: '50%',
                transform: 'translateY(-50%)',
                left: 16,
                display: 'flex',
                gap: 1,
              }}
            >
              {collectedWords.map((word, index) => (
                <Box
                  key={word.id}
                  data-word-index={index}
                  draggable
                  onDragStart={(e) => handleDragStart(e, word, index)}
                  onDragEnd={handleDragEnd}
                  onDragOver={(e) => handleDragOver(e, index)}
                  onTouchStart={(e) => handleTouchStart(e, word, index)}
                  onTouchMove={handleTouchMove}
                  onTouchEnd={handleTouchEnd}
                  sx={{
                    position: 'relative',
                    transition: 'transform 0.3s ease-in-out',
                    transform: targetIndex === index && draggedWord && draggedWord.index !== index
                      ? 'scale(1.1)'
                      : 'none',
                  }}
                >
                  <Paper
                    elevation={draggedWord?.index === index ? 3 : 1}
                    sx={{
                      px: 1.5,
                      py: 0.75,
                      bgcolor: targetIndex === index ? 'primary.light' : 'background.paper',
                      display: 'flex',
                      alignItems: 'center',
                      cursor: 'move',
                      transition: 'all 0.2s ease',
                      '&:hover': {
                        bgcolor: 'primary.light',
                        transform: draggedWord ? 'none' : 'translateY(-1px)',
                      }
                    }}
                  >
                    <Typography 
                      variant="body2"
                      sx={{ 
                        color: targetIndex === index ? 'primary.contrastText' : 'text.primary',
                        fontWeight: targetIndex === index ? 500 : 400,
                        whiteSpace: 'nowrap',
                      }}
                    >
                      {word.word}
                    </Typography>
                  </Paper>
                </Box>
              ))}
            </Box>
          </Box>

          {allWordsCollected && !wordsInCorrectOrder && (
            <Alert severity="info" sx={{ mt: 2, animation: 'fadeIn 0.5s ease-in-out' }}>
              All words collected! Now arrange them in the correct order.
            </Alert>
          )}

          {wordsInCorrectOrder && (
            <Alert severity="success" sx={{ mt: 2, animation: 'fadeIn 0.5s ease-in-out' }}>
              Congratulations! You've arranged the words in the correct order!
            </Alert>
          )}
        </Box>
      </Collapse>
      </Paper>
    </CollectorBox>
  );
};

export default WordCollector;
