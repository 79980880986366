import React, { useState } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import SocialLinks from '../components/SocialLinks';
import { mainMenu } from '../config/navigation';
import {
  Box,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Typography,
  useMediaQuery,
  useTheme,
  AppBar,
  Toolbar,
  Container,
  Grid,
  Stack,
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';

const DRAWER_WIDTH = 280;

const SidebarLayout = ({ children }) => {
  const [mobileOpen, setMobileOpen] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const location = useLocation();

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };



  const isActive = (path) => {
    if (path === '/') {
      return location.pathname === '/';
    }
    return location.pathname.startsWith(path);
  };

  const drawer = (
    <Box sx={{ height: '100%', display: 'flex', flexDirection: 'column', pt: 8, px: 4, pb: 4 }}>
      <Box sx={{ flex: 1 }}>
        <Box 
          component={RouterLink}
          to="/"
          sx={{
            display: 'block',
            width: '100%',
            maxWidth: 200,
            mb: 4,
            textDecoration: 'none',
            backgroundColor: 'grey.100',
            p: 2,
            borderRadius: 1
          }}
        >
          <Typography
            variant="h6"
            sx={{
              color: 'text.primary',
              fontWeight: 700,
              fontSize: '1.5rem',
              letterSpacing: '-0.02em',
              lineHeight: 1.2
            }}
          >
            BLACKINCH
            <br />
            STUDIO
          </Typography>
        </Box>

        {/* <Typography
          variant="body2"
          sx={{
            color: 'text.primary',
            mb: 6,
            lineHeight: 1.5
          }}
        >
          A modern portfolio focused on showcasing your work in a clean and minimal way.
        </Typography> */}

        <List sx={{ mb: 4 }}>
          {mainMenu.map((item) => (
            <ListItem
              key={item.title}
              component={RouterLink}
              to={item.path}
              onClick={isMobile ? handleDrawerToggle : undefined}
              sx={{
                px: 0,
                py: 1.5,
                borderBottom: '1px solid',
                borderColor: 'divider',
                fontWeight: isActive(item.path) ? 'bold' : 'normal',
                '&:first-of-type': {
                  borderTop: '1px solid',
                  borderColor: 'divider',
                },
                color: isActive(item.path) ? 'text.primary' : 'text.primary',
                '&:hover': {
                  color: 'text.secondary',
                  backgroundColor: 'transparent'
                }
              }}
            >
              <ListItemText 
                primary={item.title}
                primaryTypographyProps={{
                  sx: {
                    fontSize: '1rem',
                    fontWeight: isActive(item.path) ? 700 : 400
                  }
                }}
              />
            </ListItem>
          ))}
        </List>
      </Box>

      <Box>
        <SocialLinks />
      </Box>
    </Box>
  );

  return (
    <Box sx={{ display: 'flex', minHeight: '100vh' }}>
      {isMobile && (
        <AppBar 
          position="fixed" 
          elevation={0}
          sx={{
            width: '100%',
            bgcolor: 'background.default',
            borderBottom: '1px solid',
            borderColor: 'divider'
          }}
        >
          <Toolbar sx={{ minHeight: '64px' }}>
            <IconButton
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
              sx={{ 
                mr: 2, 
                color: 'text.secondary',
                '&:hover': {
                  color: 'text.primary',
                  backgroundColor: 'transparent'
                }
              }}
            >
              <MenuIcon sx={{ fontSize: '1.2rem' }} />
            </IconButton>
            <Typography 
              variant="h6" 
              component={RouterLink}
              to="/"
              sx={{ 
                color: 'text.primary', 
                textDecoration: 'none',
                fontWeight: 600,
                fontSize: '1rem',
                letterSpacing: '0.02em'
              }}
            >
              BLACKINCH STUDIO
            </Typography>
          </Toolbar>
        </AppBar>
      )}

      <Box
        component="nav"
        sx={{ width: { md: DRAWER_WIDTH }, flexShrink: { md: 0 } }}
      >
        <Drawer
          variant="temporary"
          anchor="left"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true,
          }}
          sx={{
            display: { xs: 'block', md: 'none' },
            '& .MuiDrawer-paper': {
              boxSizing: 'border-box',
              width: DRAWER_WIDTH,
              bgcolor: 'background.default',
              borderRight: '1px solid',
              borderColor: 'divider'
            },
          }}
        >
          {drawer}
        </Drawer>

        <Drawer
          variant="permanent"
          sx={{
            display: { xs: 'none', md: 'block' },
            '& .MuiDrawer-paper': {
              boxSizing: 'border-box',
              width: DRAWER_WIDTH,
              bgcolor: 'background.default',
              borderRight: '1px solid',
              borderColor: 'divider'
            },
          }}
          open
        >
          {drawer}
        </Drawer>
      </Box>

      <Box
        component="main"
        sx={{
          flexGrow: 1,
          width: { md: `calc(100% - ${DRAWER_WIDTH}px)` },
          pt: { xs: 8, md: 0 },
          display: 'flex',
          flexDirection: 'column',
          minHeight: '100vh',
          margin: '0 auto',
          maxWidth: '1200px'
        }}
      >
        <Box sx={{ flexGrow: 1 }}>
          {children}
        </Box>
      </Box>
    </Box>
  );
};

export default SidebarLayout;
