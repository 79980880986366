import React, { useState, useEffect, useRef } from 'react';
import {
  Grid,
  Box,
  Modal,
  IconButton,
  Button,
  Skeleton,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';

const PortfolioGrid = ({ projects }) => {
  const [selectedProject, setSelectedProject] = useState(null);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [isTransitioning, setIsTransitioning] = useState(false);
  const [displayedImageIndex, setDisplayedImageIndex] = useState(0);
  const [currentImageDimensions, setCurrentImageDimensions] = useState({ width: 0, height: 0 });
  const [nextImageDimensions, setNextImageDimensions] = useState({ width: 0, height: 0 });
  const [isLoading, setIsLoading] = useState(false);
  const [loadedImages, setLoadedImages] = useState({});
  const observerRef = useRef({});
  
  // Debounce function to prevent too many resize calculations
  const debounce = (func, wait) => {
    let timeout;
    return function executedFunction(...args) {
      const later = () => {
        clearTimeout(timeout);
        func(...args);
      };
      clearTimeout(timeout);
      timeout = setTimeout(later, wait);
    };
  };

  const preloadImage = (src) => {
    return new Promise((resolve) => {
      const img = new Image();
      img.onload = () => resolve({ width: img.width, height: img.height });
      img.src = src;
    });
  };

  const updateImageDimensions = async (index, project = selectedProject) => {
    if (project) {
      const dimensions = await preloadImage(project.images[index]);
      const padding = window.innerWidth < 600 ? 48 : 96; // Account for modal padding and margins
      const maxWidth = window.innerWidth - padding;
      const maxHeight = window.innerHeight - (window.innerWidth < 600 ? 160 : 240); // Account for modal padding, margins, and text
      
      // Calculate aspect ratio constrained dimensions
      const aspectRatio = dimensions.width / dimensions.height;
      let width = dimensions.width;
      let height = dimensions.height;
      
      // First, try to fit by width
      if (width > maxWidth) {
        width = maxWidth;
        height = width / aspectRatio;
      }
      
      // Then check height and adjust if needed
      if (height > maxHeight) {
        height = maxHeight;
        width = height * aspectRatio;
      }
      
      // Final width check in case height adjustment made it too wide
      if (width > maxWidth) {
        width = maxWidth;
        height = width / aspectRatio;
      }
      
      return { width, height };
    }
    return { width: window.innerWidth - (window.innerWidth < 600 ? 32 : 64), height: 300 };
  };

  const handleOpen = async (project) => {
    setIsLoading(true);
    
    try {
      // Calculate dimensions first
      const dimensions = await updateImageDimensions(0, project);
      
      // Then update all state at once
      setCurrentImageDimensions(dimensions);
      setNextImageDimensions(dimensions);
      setCurrentImageIndex(0);
      setDisplayedImageIndex(0);
      setIsTransitioning(false);
      setSelectedProject(project);
    } catch (error) {
      console.error('Error loading image:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleClose = () => {
    setSelectedProject(null);
    setCurrentImageIndex(0);
    setDisplayedImageIndex(0);
    setIsTransitioning(false);
    setCurrentImageDimensions({ width: 0, height: 0 });
    setNextImageDimensions({ width: 0, height: 0 });
  };

  // Handle window resize
  const handleResize = async () => {
    if (selectedProject && !isTransitioning) {
      const dimensions = await updateImageDimensions(currentImageIndex);
      setCurrentImageDimensions(dimensions);
      setNextImageDimensions(dimensions);
    }
  };

  // Set up resize listener
  useEffect(() => {
    const debouncedHandleResize = debounce(handleResize, 100);
    window.addEventListener('resize', debouncedHandleResize);
    
    return () => {
      window.removeEventListener('resize', debouncedHandleResize);
    };
  }, [selectedProject, currentImageIndex, isTransitioning]);


  const handleNext = async () => {
    if (selectedProject && currentImageIndex < selectedProject.images.length - 1 && !isTransitioning) {
      setIsTransitioning(true);
      const nextIndex = currentImageIndex + 1;
      const dimensions = await updateImageDimensions(nextIndex);
      setNextImageDimensions(dimensions);
      setCurrentImageIndex(nextIndex);
      
      setTimeout(() => {
        setCurrentImageDimensions(dimensions);
        setDisplayedImageIndex(nextIndex);
        setIsTransitioning(false);
      }, 600);
    }
  };

  const handlePrevious = async () => {
    if (selectedProject && currentImageIndex > 0 && !isTransitioning) {
      setIsTransitioning(true);
      const prevIndex = currentImageIndex - 1;
      const dimensions = await updateImageDimensions(prevIndex);
      setNextImageDimensions(dimensions);
      setCurrentImageIndex(prevIndex);
      
      setTimeout(() => {
        setCurrentImageDimensions(dimensions);
        setDisplayedImageIndex(prevIndex);
        setIsTransitioning(false);
      }, 600);
    }
  };

  return (
    <>
      <Box sx={{ width: '100%', overflow: 'hidden' }}>
        <Grid container spacing={2}>
          {projects.map((project) => (
            <Grid item xs={12} sm={6} md={4} key={project.id}>
              <Box
                onClick={() => handleOpen(project)}
                sx={{
                  position: 'relative',
                  paddingTop: { xs: '75%', sm: '100%' },
                  cursor: 'pointer',
                  '&:hover': {
                    '& .hover-overlay': {
                      opacity: 1,
                    },
                  },
                  bgcolor: 'background.paper',
                }}
              >
                {!loadedImages[project.id] && (
                  <Skeleton
                    variant="rectangular"
                    sx={{
                      position: 'absolute',
                      top: 0,
                      left: 0,
                      width: '100%',
                      height: '100%',
                      transform: 'none',
                    }}
                    animation="wave"
                  />
                )}
                <Box
                  component="img"
                  ref={(el) => {
                    if (el && !observerRef.current[project.id]) {
                      observerRef.current[project.id] = new IntersectionObserver(
                        ([entry]) => {
                          if (entry.isIntersecting) {
                            el.src = project.thumbnail;
                            el.onload = () => setLoadedImages(prev => ({ ...prev, [project.id]: true }));
                            observerRef.current[project.id].disconnect();
                            delete observerRef.current[project.id];
                          }
                        },
                        { rootMargin: '50px' }
                      );
                      observerRef.current[project.id].observe(el);
                    }
                  }}
                  alt={project.title}
                  sx={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    objectFit: 'cover',
                    transition: 'transform 0.3s ease-in-out',
                    opacity: loadedImages[project.id] ? 1 : 0,
                    '&:hover': {
                      transform: 'scale(1.05)',
                    },
                  }}
                />
                <Box
                  className="hover-overlay"
                  sx={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    bgcolor: 'rgba(0, 0, 0, 0.7)',
                    color: 'white',
                    opacity: 0,
                    transition: 'opacity 0.3s ease-in-out',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    padding: 2,
                    textAlign: 'center',
                  }}
                >
                  <Box sx={{ typography: 'h6', mb: 1 }}>{project.title}</Box>
                  {/* <Box sx={{ typography: 'body2' }}>{project.description}</Box> */}
                </Box>
              </Box>
            </Grid>
          ))}
        </Grid>
      </Box>

      <Modal
        open={Boolean(selectedProject)}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Box
          sx={{
            position: 'relative',
            bgcolor: 'background.paper',
            boxShadow: 24,
            pl: 3,
            pr: 3,
            pt: 5,
            pb: 2,
            outline: 'none',
            width: 'auto',
            m: 2,
            display: 'flex',
            flexDirection: 'column',
            opacity: isLoading ? 0 : 1,
            transition: 'opacity 0.3s ease-in-out',
            '& h6': { // Title styles
              fontSize: { xs: '1.1rem', sm: '1.25rem' },
              mb: { xs: 1, sm: 2 },
              overflowWrap: 'break-word',
              width: '100%'
            },
            '& p': { // Description styles
              fontSize: { xs: '0.875rem', sm: '1rem' },
              mt: { xs: 1, sm: 2 },
              overflowWrap: 'break-word',
              width: '100%'
            }
          }}
        >
          <IconButton
            onClick={handleClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
           
            }}
          >
            <CloseIcon />
          </IconButton>
          {selectedProject && (
            <>
              <Box sx={{ 
            position: 'relative',
            flex: '1 0 auto',
            overflow: 'hidden'
          }}>
                <Box 
                  sx={{
                    position: 'relative',
                    width: 'auto',
                    height: 'auto',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    bgcolor: 'background.default',
                    mx: 'auto',
                    mb: 2,
                    width: { xs: '80vw', sm: '70vh' },
                    '& img': {
                      maxWidth: '100%',
                      maxHeight: '70vh',
                      width: 'auto',
                      height: 'auto',
                      objectFit: 'contain'
                    }
                  }}
                >
                  <Box
                    component="img"
                    src={selectedProject.images[displayedImageIndex]}
                    alt={`${selectedProject.title} - Image ${currentImageIndex + 1}`}
                    sx={{
                      display: 'block',
                      position: 'relative',
                      width: '100%',
                      height: '100%',
                      objectFit: 'contain',
                      opacity: 1,
                      transition: 'opacity 0.6s ease-in-out',
                      zIndex: isTransitioning ? 1 : 2,
                    }}
                  />
                  <Box
                    component="img"
                    src={selectedProject.images[currentImageIndex]}
                    alt={`${selectedProject.title} - Image ${currentImageIndex + 1}`}
                    sx={{
                      display: 'block',
                      position: 'absolute',
                      top: 0,
                      left: 0,
                      width: '100%',
                      height: '100%',
                      objectFit: 'contain',
                      opacity: isTransitioning ? 1 : 0,
                      transition: 'opacity 0.6s ease-in-out',
                      zIndex: isTransitioning ? 2 : 1,
                    }}
                  />
                  {selectedProject.images.length > 1 && (
                    <Box sx={{ position: 'absolute', top: 0, left: 0, right: 0, bottom: 0, zIndex: 3, pointerEvents: 'none' }}>
                      <IconButton
                        onClick={handlePrevious}
                        disabled={currentImageIndex === 0}
                        sx={{
                          position: 'absolute',
                          left: { xs: 4, sm: 16 },
                          top: '50%',
                          transform: 'translateY(-50%)',
                          bgcolor: 'rgba(255, 255, 255, 0.8)',
                          '&:hover': { bgcolor: 'rgba(255, 255, 255, 0.9)' },
                          pointerEvents: 'auto',
                          boxShadow: '0 2px 4px rgba(0,0,0,0.2)',
                          width: { xs: 32, sm: 40 },
                          height: { xs: 32, sm: 40 },
                          '& svg': {
                            fontSize: { xs: '1.25rem', sm: '1.5rem' }
                          }
                        }}
                      >
                        <NavigateBeforeIcon />
                      </IconButton>
                      <IconButton
                        onClick={handleNext}
                        disabled={currentImageIndex === selectedProject.images.length - 1}
                        sx={{
                          position: 'absolute',
                          right: { xs: 4, sm: 16 },
                          top: '50%',
                          transform: 'translateY(-50%)',
                          bgcolor: 'rgba(255, 255, 255, 0.8)',
                          '&:hover': { bgcolor: 'rgba(255, 255, 255, 0.9)' },
                          pointerEvents: 'auto',
                          boxShadow: '0 2px 4px rgba(0,0,0,0.2)',
                          width: { xs: 32, sm: 40 },
                          height: { xs: 32, sm: 40 },
                          '& svg': {
                            fontSize: { xs: '1.25rem', sm: '1.5rem' }
                          }
                        }}
                      >
                        <NavigateNextIcon />
                      </IconButton>
                    </Box>
                  )}
                </Box>
              </Box>
              <Box sx={{ 
                mt: 0,
                flex: '0 0 auto',
                width: '100%',
                maxWidth: { xs: '80vw', sm: '70vh' }
              }}>
                <Box sx={{ 
                  typography: 'h5', 
                  mb: 1,
                  wordBreak: 'break-word',
                  whiteSpace: 'pre-wrap'
                }}>
                  {selectedProject.title}
                </Box>
                <Box sx={{ 
                  typography: 'body1', 
                  mb: 2,
                  wordBreak: 'break-word',
                  whiteSpace: 'pre-wrap'
                }}>
                  {selectedProject.description}
                </Box>
                {selectedProject.images.length > 1 && (
                  <Box sx={{ typography: 'body2', color: 'text.secondary' }}>
                    Image {currentImageIndex + 1} of {selectedProject.images.length}
                  </Box>
                )}
              </Box>
            </>
          )}
        </Box>
      </Modal>
    </>
  );
};

export default PortfolioGrid;
