import React from 'react';
import { Grid, Typography, Box, Link } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

const ServiceCard = ({ service }) => (
  <Link 
    component={RouterLink} 
    to={`/creative-things/${service.id}`}
    sx={{ 
      textDecoration: 'none',
      color: 'inherit',
      display: 'block',
      '&:hover': {
        '& .arrow': {
          transform: 'translateX(4px)'
        },
        '& img': {
          filter: 'brightness(0.9)'
        }
      }
    }}
  >

    
    <Box
      component="img"
      src={service.image}
      alt={service.title}
      sx={{
        width: '100%',
        aspectRatio: '4/3',
        objectFit: 'cover',
        mb: 2,
        transition: 'filter 0.3s ease'
      }}
    />
    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
      <Box>
        <Typography variant="h5" sx={{ mb: 0.5 }}>
          {service.title}
        </Typography>
        <Typography variant="body2">
          {service.shortDescription}
        </Typography>
      </Box>
      <ArrowForwardIcon 
        className="arrow"
        sx={{ 
          ml: 2,
          transition: 'transform 0.3s ease'
        }} 
      />
    </Box>
  </Link>
);

const ServiceGrid = ({ services }) => (
  <>

    <Grid container spacing={3}>
      {Object.values(services).map((service) => (
        <Grid item xs={12} sm={6} md={4} key={service.id}>
          <ServiceCard service={service} />
        </Grid>
      ))}
    </Grid>
  </>
);

export default ServiceGrid;
