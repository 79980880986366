import React from 'react';
import PropTypes from 'prop-types';
import { Box, Typography } from '@mui/material';

const Hero = ({ title, subtitle }) => (
  <Box sx={{ mb: 8 }}>
    <Typography variant="h1" gutterBottom sx={{ mb: 2 }}>
      {title}
    </Typography>
    <Typography
      variant="body1"
      sx={{
        fontSize: { xs: '1rem', md: '1.125rem' },
        maxWidth: '600px',
        mb: 8
      }}
    >
      {subtitle}
    </Typography>
  </Box>
);

Hero.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired
};

export default Hero;
